

















































































































































































































































































































































































































































































import { SfButton, SfInput, SfLink, SfNotification } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { digits, email, min, numeric, required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { ref, useContext, useFetch, useRouter, watch } from '@nuxtjs/composition-api';
import { useConfig, useSubAccount, useUiNotification, useUser, } from '~/composables';
import {
  IS_VIEW_ORDER_ALL,
  IS_VIEW_ORDER_NOT_ALLOWED,
  IS_VIEW_ORDER_ONLY_SUBACCOUNT,
  SUBACCOUNT_INACTIVE,
  SUBACCOUNT_PENDING
} from '~/helpers/customer/role';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('digits', {
  ...digits,
  message: 'Invalid format'
});

extend('numeric', {
  ...numeric,
  message: 'Invalid format'
});

export default {
  components: {
    SfLink,
    SfButton,
    SfInput,
    SfNotification,
    SvgImage,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    subAccountId: { type: String, required: false }
  },

  setup(props) {
    type Form = {
      lastname: string,
      firstname: string,
      email: string,
      telephone: string,
      role: {
        entity_id: number | null,
        customer_id: number | null,
        active: number,
        is_view_price: boolean
        is_view_order: number
        is_view_invoices: boolean
        is_view_quotations: boolean
        is_view_outstanding: boolean
        is_validate_order: boolean
        ceiling_validated_order: boolean | number
        is_notification_order: boolean
        is_pay_cd: boolean
        is_pay_outstanding: boolean
      }
    };

    const { config } = useConfig();
    const { create, get: getSubAccount, update } = useSubAccount();
    const { app } = useContext();
    const router = useRouter();
    const { load: loadUser } = useUser();
    const { send: sendNotification } = useUiNotification();

    const error = ref<string>('');

    const ceilingPrice = ref<number>(0);
    const paymentCommmitment = ref<boolean>(false);
    const isViewOrderAll = ref<boolean>(false);
    const isViewOrderSub = ref<boolean>(false);

    let formError = ref('')
    const form = ref<Form>({
      lastname: '',
      firstname: '',
      email: '',
      telephone: '',
      role: {
        entity_id: null,
        customer_id: null,
        active: 0,
        is_view_price: false,
        is_view_order: IS_VIEW_ORDER_NOT_ALLOWED,
        is_view_invoices: false,
        is_view_quotations: false,
        is_view_outstanding: false,
        is_validate_order: false,
        ceiling_validated_order: 0,
        is_notification_order: false,
        is_pay_cd: false,
        is_pay_outstanding: true
      }
    });

    // Si un subAccountId est présent on hydrate le formulaire avec les données du sous-compte
    if (props.subAccountId) {
      useFetch(async () => {
        const userResult = await loadUser({ customQuery: { customer: 'customCustomer' } });
        if (userResult.role.cod_client) {
          // Récupération du sous-compte
          const subAccountId = parseInt(props.subAccountId);
          let subAccount = await getSubAccount(userResult.role.cod_client, subAccountId);

          if (subAccount) {
            form.value.lastname = subAccount.lastname;
            form.value.firstname = subAccount.firstname;
            form.value.email = subAccount.email;
            form.value.telephone = subAccount.telephone;
            form.value.role.entity_id = subAccount.role.entity_id;
            form.value.role.customer_id = subAccount.role.customer_id;
            form.value.role.active = subAccount.role.active;
            form.value.role.is_view_price = subAccount.role.is_view_price;
            form.value.role.is_view_order = subAccount.role.is_view_order;
            form.value.role.is_view_invoices = subAccount.role.is_view_invoices;
            form.value.role.is_view_quotations = subAccount.role.is_view_quotations;
            form.value.role.is_view_outstanding = subAccount.role.is_view_outstanding;
            form.value.role.is_validate_order = subAccount.role.is_validate_order;
            form.value.role.ceiling_validated_order = subAccount.role.ceiling_validated_order;
            form.value.role.is_notification_order = subAccount.role.is_notification_order;
            form.value.role.is_pay_cd = subAccount.role.is_pay_cd;
            form.value.role.is_pay_outstanding = subAccount.role.is_pay_outstanding;
            ceilingPrice.value = subAccount.role.ceiling_validated_order;
            isViewOrderSub.value = subAccount.role.is_view_order === IS_VIEW_ORDER_ONLY_SUBACCOUNT;
            isViewOrderAll.value = subAccount.role.is_view_order === IS_VIEW_ORDER_ALL;
          }
        }
      });
    }

    watch(() => form.value.role.is_view_invoices, () => {
      if (form.value.role.is_view_order === false) {
        form.value.role.is_view_invoices = false;
      }
    });

    watch(() => form.value.role.is_view_order, () => {
      if (form.value.role.is_view_order === false) {
        form.value.role.is_view_invoices = false;
      }
    });

    watch(() => form.value.role.is_view_price, () => {
      if (form.value.role.is_view_price === false) {
        form.value.role.is_pay_cd = false;
        form.value.role.is_view_invoices = false;
        form.value.role.is_view_outstanding = false;
        form.value.role.is_pay_outstanding = true;
      }
    });

    watch(() => form.value.role.ceiling_validated_order, () => {
      if (form.value.role.ceiling_validated_order === false) {
        ceilingPrice.value = 0;
      } else if (form.value.role.ceiling_validated_order === true) {
        form.value.role.is_validate_order = false;
      }
    });

    watch(() => form.value.role.is_validate_order, () => {
      if (form.value.role.is_validate_order === true) {
        form.value.role.ceiling_validated_order = false;
      }
    });

    const submitForm = (resetValidationFn: () => void) => async () => {
      const data = Object.assign({}, form.value);

      data.role.ceiling_validated_order = ceilingPrice.value;
      data.role.active = data.role.active ? SUBACCOUNT_PENDING : SUBACCOUNT_INACTIVE;

      // Affecte le rôle "is_view_order"
      if (data.role.is_view_order) {
        if (isViewOrderSub.value === true) {
          data.role.is_view_order = IS_VIEW_ORDER_ONLY_SUBACCOUNT;
        } else if (isViewOrderAll.value === true) {
          data.role.is_view_order = IS_VIEW_ORDER_ALL;
        } else {
          data.role.is_view_order = IS_VIEW_ORDER_NOT_ALLOWED;
        }
      }

      const result = data.role.entity_id ? await update(data) : await create(data);

      resetValidationFn();

      if (result.errors) {
        formError.value = result.errors[0].message;
      } else {
        await router.push({ name: 'customer-my-sub-accounts', query: { isSuccess: true } })
      }
    };

    // Récupération configuration Magento
    let description = ref('');
    const messages           = config.value?.subaccount_messages || [];
    const messageDescription = messages.find((message) => message.key === 'message_status');
    description.value = messageDescription?.value;

    return {
      description,
      ceilingPrice,
      isViewOrderSub,
      isViewOrderAll,
      paymentCommmitment,
      form,
      error,
      submitForm,
      formError
    };
  }
}
